"use client";

import { useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import type { Session } from "next-auth";

export default function ClientLayout({
  children,
  session,
  redirect,
}: {
  children: React.ReactNode;
  session: Session | null;
  redirect: string;
}) {
  const router = useRouter();
  const pathName = usePathname();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    if (
      !session &&
      pathName !== "/logged-out" &&
      pathName !== "/browse-listings"
    ) {
      router.replace(redirect);
    }

    if (
      (session && pathName === "/logged-out") ||
      pathName === "/browse-listings"
    ) {
      router.replace(redirect);
    }
  }, [router, session]);

  if (!mounted) return null;

  return <>{children}</>;
}
