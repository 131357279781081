"use client";

import { Suspense, useEffect, useState } from "react";
import { handlers } from "./handlers";

export function MSWProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <Suspense fallback={null}>
      <MSWProviderWrapper>{children}</MSWProviderWrapper>
    </Suspense>
  );
}

function MSWProviderWrapper({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [mockingEnabled, setMockingEnabled] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      import("./browser").then(async ({ worker }) => {
        await worker.start({
          onUnhandledRequest(request, print) {
            if (request.url.includes("_next")) {
              return;
            }
            print.warning();
          },
        });
        worker.use(...handlers);

        setMockingEnabled(true); // Set mockingEnabled to true after worker starts
      });
    }
  }, []);

  // Only render children if mocking is enabled
  return mockingEnabled ? children : null;
}
