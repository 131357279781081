import { http, HttpResponse, delay } from "msw";
// import { getLaravelMock } from "@repo/api/axios";
import { db } from "./db";

export const handlers = [
  // ...getLaravelMock(),

  http.get("https://backend-staging.diablo.trade/api/listingTypes", () => {
    return HttpResponse.json(db.listingTypes.getAll());
  }),
  http.get("https://backend-staging.diablo.trade/api/rarities", () => {
    return HttpResponse.json(db.rarities.getAll());
  }),
  http.get("https://backend-staging.diablo.trade/api/gameplayModes", () => {
    return HttpResponse.json(db.gameplayModes.getAll());
  }),
  http.get("https://backend-staging.diablo.trade/api/tiers", () => {
    return HttpResponse.json(db.tiers.getAll());
  }),
  http.get("https://backend-staging.diablo.trade/api/itemTypes", () => {
    return HttpResponse.json(db.itemTypes.getAll());
  }),
  http.get("https://backend-staging.diablo.trade/api/classRestrictions", () => {
    return HttpResponse.json(db.classRestrictions.getAll());
  }),
  http.get("https://backend-staging.diablo.trade/api/auctionTypes", () => {
    return HttpResponse.json(db.auctionTypes.getAll());
  }),
  http.get("https://backend-staging.diablo.trade/api/implicits", () => {
    return HttpResponse.json(db.implicits.getAll());
  }),
  http.get("https://backend-staging.diablo.trade/api/affixes", () => {
    return HttpResponse.json(db.affixes.getAll());
  }),
  http.get("https://backend-staging.diablo.trade/api/aspects", () => {
    return HttpResponse.json(db.aspects.getAll());
  }),
  http.post("https://backend-staging.diablo.trade/api/listings", () => {
    return HttpResponse.json(db.aspects.getAll());
  }),
  http.post("https://backend-staging.diablo.trade/api/categories", () => {
    return HttpResponse.json(db.categories.getAll());
  }),
  http.post(
    "https://backend-staging.diablo.trade/api/service-categories",
    () => {
      return HttpResponse.json(db.serviceCategories.getAll());
    },
  ),
  http.post("https://backend-staging.diablo.trade/api/activities", () => {
    return HttpResponse.json(db.activities.getAll());
  }),
  http.post("https://backend-staging.diablo.trade/api/materials", () => {
    return HttpResponse.json(db.materials.getAll());
  }),
  http.post("https://backend-staging.diablo.trade/api/user", async ({ request }) => {
    interface User {
      id: string,
      emailData: {
        emailNotification: boolean,
        pushNotification: boolean,
        email: string,
      }
    }
    const body = await request.json() as User
    const { id, ...rest } = body;

    const existingUser = db.users.findFirst({ where: { id: { equals: id } } });

    if (!existingUser) {
      const user = db.users.create({
        id,
        ...rest,
      });

      return HttpResponse.json(user, { status: 201 });
    }
    const user = db.users.update({
      where: { id: { equals: id } },
      data: {
        emailData: {
          email: body.emailData.email,
          emailNotification: body.emailData.emailNotification,
          pushNotification: body.emailData.pushNotification,
        }
      },
    });
    return HttpResponse.json(user, { status: 201 });


  }),

  http.post(
    "https://backend-staging.diablo.trade/api/listings/preview",
    async ({ request }) => {
      const data = await request.formData();
      const file = data.get("file");

      if (!file) {
        return new HttpResponse("Missing image", { status: 400 });
      }

      if (!(file instanceof File)) {
        return new HttpResponse("Uploaded document is not a File", {
          status: 400,
        });
      }

      await delay(600);

      return HttpResponse.json({
        image: "",
        rarity: "Rare",
        name: "Ancestral Legendary",
        level_requirement: 1,
        user_id: "",
        mode: "Seasonal Hardcore",
        item_power: 1,
        price: 0,
        category: "",
        status: "",
        request_type: "",
        exact_price: 1,
        item_type: "Equipment",
      });
    },
  ),
];
