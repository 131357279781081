// @ts-nocheck
import { factory, primaryKey } from "@mswjs/data";
import { faker } from "@faker-js/faker";

export const db = factory({
  users: {
    id: primaryKey(String),
    emailData: {
      emailNotification: Boolean,
      pushNotification: Boolean,
      email: String,
    }
  },
  items: {
    id: primaryKey(faker.string.uuid),
    image: String,
    rarity: String,
    name: String,
    level_requirement: Number,
    user_id: String,
    mode: String,
    item_power: Number,
    price: Number,
    category: String,
    status: String,
    request_type: String,
    exact_price: Number,
    item_type: String,
  },
  listingTypes: {
    id: primaryKey(faker.string.uuid),
    name: String,
  },
  itemTypes: {
    id: primaryKey(faker.string.uuid),
    name: String,
  },
  auctionTypes: {
    id: primaryKey(faker.string.uuid),
    name: String,
  },
  classRestrictions: {
    id: primaryKey(faker.string.uuid),
    name: String,
  },
  implicits: {
    id: primaryKey(faker.string.uuid),
    name: String,
  },
  aspects: {
    id: primaryKey(faker.string.uuid),
    name: String,
  },
  affixes: {
    id: primaryKey(faker.string.uuid),
    name: String,
  },
  rarities: {
    id: primaryKey(faker.string.uuid),
    name: String,
  },
  gameplayModes: {
    id: primaryKey(faker.string.uuid),
    name: String,
    thumbnailUrl: String,
  },
  tiers: {
    id: primaryKey(faker.string.uuid),
    name: String,
  },
  serviceCategories: {
    id: primaryKey(faker.string.uuid),
    name: String,
  },
  categories: {
    id: primaryKey(faker.string.uuid),
    name: String,
  },
  activities: {
    id: primaryKey(faker.string.uuid),
    name: String,
  },
  materials: {
    id: primaryKey(faker.string.uuid),
    name: String,
  },
});

// const user = db.users.create({
//   id: faker.string.uuid(),
// });

function seed() {
  ["Equipment", "Services", "Consumables & Materials"].forEach((listing) => {
    db.listingTypes.create({
      id: faker.string.uuid(),
      name: listing,
    });
  });

  ["Rare", "Unique", "Legendary"].forEach((rarity) => {
    db.rarities.create({
      id: faker.string.uuid(),
      name: rarity,
    });
  });

  ["Ancestral", "Sacred", "None"].forEach((tier) => {
    db.tiers.create({
      id: faker.string.uuid(),
      name: tier,
    });
  });

  [
    "Amulet",
    "Boots",
    "Chest Armor",
    "Gloves",
    "Helm",
    "Pants",
    "Ring",
    "Axe",
    "Bow",
    "Crossbow",
    "Dagger",
    "Focus",
    "Mace",
    "Polearm",
    "Scythe",
    "Shield",
    "Staff",
    "Sword",
    "Totem",
    "Two-Handed Axe",
    "Two-Handed Mace",
    "Two-Handed Scythe",
    "Two-Handed Sword",
    "Wand",
  ].forEach((item) => {
    db.itemTypes.create({
      id: faker.string.uuid(),
      name: item,
    });
  });

  ["Barbarian", "Druid", "Necromancer", "Rogue", "Sorcerer", "None"].forEach(
    (classRestriction) => {
      db.classRestrictions.create({
        id: faker.string.uuid(),
        name: classRestriction,
      });
    },
  );

  [
    {
      name: "Seasonal Hardcore",
      thumbnailUrl: "/images/modes/seasonal_hardcore.webp",
    },
    {
      name: "Seasonal Softcore",
      thumbnailUrl: "/images/modes/seasonal_softcore.webp",
    },
    {
      name: "Eternal Hardcore",
      thumbnailUrl: "/images/modes/eternal_hardcore.png",
    },
    {
      name: "Eternal Softcore",
      thumbnailUrl: "/images/modes/eternal_softcore.png",
    },
  ].forEach((mode) => {
    db.gameplayModes.create({
      id: faker.string.uuid(),
      ...mode,
    });
  });

  ["Bid Only", "Buyout Only", "Bid & Buyout"].forEach((tier) => {
    db.auctionTypes.create({
      id: faker.string.uuid(),
      name: tier,
    });
  });

  [
    "Max Evade Charge",
    "Attacks Reduce Evade's Cooldown by Seconds",
    "Evade Grants 125% Movement Speed for 2 Seconds",
    "Evade Grants Unhindered for 2 Seconds",
    "Resistance to All Elements",
  ].forEach((tier) => {
    db.implicits.create({
      id: faker.string.uuid(),
      name: tier,
    });
  });

  [
    "Total Armor",
    "To Precision Imbuement",
    "Attack Speed",
    "Cooldown Reduction",
    "Dexterity",
    "Intelligence",
    "Strength",
    "Willpower",
    "Critical Strike Chance",
    "Damage",
    "Life On Hit",
    "Life per Second",
    "Lucky Hit Chance",
    "Lucky Hit: Up to a Chance to Make Enemies Vulnerable for 2 Seconds",
    "Movement Speed",
    "To Counteroffensive",
    "To Heavy Handed",
    "To Cut to the Bone",
    "To Slaying Strike",
    "To Brute Force",
    "To No Mercy",
    "To Nature's Reach",
    "To Wild Impulses",
    "To Envenom",
    "To Crushing Earth",
    "To Resonance",
    "To Defiance",
    "To Wallop",
    "To Call of the Wild",
    "To Quickshift",
    "To Gloom",
    "To Toxic Claws",
    "To Death's Embrace",
    "To Terror",
    "To Coalesced Blood",
    "To Imperfectly Balanced",
    "To Compound Fracture",
    "To Tides of Blood",
    "To Evulsion",
    "To Hellbent Commander",
  ].forEach((tier) => {
    db.affixes.create({
      id: faker.string.uuid(),
      name: tier,
    });
  });

  ["Aspect 1", "Aspect 2"].forEach((tier) => {
    db.aspects.create({
      id: faker.string.uuid(),
      name: tier,
    });
  });

  ["Service Category 1", "Service Category 2"].forEach((tier) => {
    db.serviceCategories.create({
      id: faker.string.uuid(),
      name: tier,
    });
  });

  ["Category 1", "Category 2"].forEach((tier) => {
    db.categories.create({
      id: faker.string.uuid(),
      name: tier,
    });
  });

  ["Activity 1", "Activity 2"].forEach((tier) => {
    db.activities.create({
      id: faker.string.uuid(),
      name: tier,
    });
  });

  ["Material 1", "Material 2"].forEach((tier) => {
    db.materials.create({
      id: faker.string.uuid(),
      name: tier,
    });
  });

}

seed();

// db.items.create({
//   id: faker.string.uuid(),
//   name: "Type 1",
//   rarity: "rare",
//   image: "",
//   level_requirement: 1,
//   user_id: user.id,
//   mode: "",
//   item_power: 0,
//   price: 0,
//   category: "",
//   status: "",
//   request_type: "",
//   exact_price: 0,
//   item_type: "",
// });

// db.items.create({
//   id: faker.string.uuid(),
//   name: "Type 2",
//   rarity: "rare",
//   image: "",
//   level_requirement: 1,
//   user_id: user.id,
//   mode: "",
//   item_power: 0,
//   price: 0,
//   category: "",
//   status: "",
//   request_type: "",
//   exact_price: 0,
//   item_type: "",
// });
