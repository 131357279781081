import type { Option } from "../types";
import type { GamplayModeOption } from "@repo/ui/gameplay-mode-select";
export const rarityOptions: Option[] = [
  {
    label: "Legendary",
    value: "legendary",
  },
  {
    label: "Rare",
    value: "rare",
  },
  {
    label: "Unique",
    value: "unique",
  },
];

export const itemTypeOptions: Option[] = [
  { label: "Amulet", value: "amulet" },
  { label: "Boots", value: "boots" },
  { label: "Chest Armor", value: "chest-armor" },
  { label: "Gloves", value: "gloves" },
  { label: "Helm", value: "helm" },
  { label: "Pants", value: "pants" },
  { label: "Ring", value: "ring" },
  { label: "Axe", value: "axe" },
  { label: "Bow", value: "bow" },
  { label: "Crossbow", value: "crossbow" },
  { label: "Dagger", value: "dagger" },
  { label: "Focus", value: "focus" },
  { label: "Mace", value: "mace" },
  { label: "Polearm", value: "polearm" },
  { label: "Scythe", value: "scythe" },
  { label: "Shield", value: "shield" },
  { label: "Staff", value: "staff" },
  { label: "Sword", value: "sword" },
  { label: "Totem", value: "totem" },
  { label: "Two-Handed Axe", value: "two-handed-axe" },
  { label: "Two-Handed Mace", value: "two-handed-mace" },
  { label: "Two-Handed Scythe", value: "two-handed-scythe" },
  { label: "Two-Handed Sword", value: "two-handed-sword" },
  { label: "Wand", value: "wand" },
];

export const listingTypeOptions: Option[] = [
  {
    label: "Exact Price",
    value: "exactPrice",
  },
  {
    label: "Taking Offers",
    value: "takingOffers",
  },
];

export const tierOptions: Option[] = [
  {
    label: "Ancestral",
    value: "ansestral",
  },
  {
    label: "Sacred",
    value: "sacred",
  },
  {
    label: "None",
    value: "none",
  },
];

export const gameplayOptions: GamplayModeOption[] = [
  {
    value: "seasonal_hardcore",
    label: "Seasonal Hardcore",
    thumbnailUrl: "/images/modes/seasonal_hardcore.webp",
  },
  {
    value: "seasonal_softcore",
    label: "Seasonal softcore",
    thumbnailUrl: "/images/modes/seasonal_softcore.webp",
  },
  {
    value: "eternal_hardcore",
    label: "Eternal hardcore",
    thumbnailUrl: "/images/modes/eternal_hardcore.png",
  },
  {
    value: "eternal_softcore",
    label: "Eternal softcore",
    thumbnailUrl: "/images/modes/eternal_softcore.png",
  },
];

export const classRestrictionOptions: Option[] = [
  {
    label: "Barbarian",
    value: "barbarian",
  },
  {
    label: "Druid",
    value: "druid",
  },
  {
    label: "Necromancer",
    value: "necromancer",
  },

  {
    label: "Rogue",
    value: "rogue",
  },
  {
    label: "Sorcerer",
    value: "sorcerer",
  },
  {
    label: "None",
    value: "none",
  },
];

export const affixOptions: Option[] = [
  {
    label: "Total Armor",
    value: "totalArmor",
  },
  {
    label: "To Precision Imbuement",
    value: "precisionImbuement",
  },
  {
    label: "Attack Speed",
    value: "attackSpeed",
  },
  {
    label: "Cooldown Reduction",
    value: "cooldownReduction",
  },
  {
    label: "Dexterity",
    value: "dexterity",
  },
  {
    label: "Intelligence",
    value: "intelligence",
  },
  {
    label: "Strength",
    value: "strength",
  },
  {
    label: "Willpower",
    value: "willpower",
  },
  {
    label: "Critical Strike Chance",
    value: "criticalStrikeChance",
  },
  {
    label: "Damage",
    value: "damage",
  },
  {
    label: "Life On Hit",
    value: "lifeOnHit",
  },
  {
    label: "Life per Second",
    value: "lifePerSecond",
  },
  {
    label: "Lucky Hit Chance",
    value: "luckyHitChance",
  },
  {
    label: "Lucky Hit: Up to a Chance to Make Enemies Vulnerable for 2 Seconds",
    value: "luckyHitVulnerable",
  },
  {
    label: "Movement Speed",
    value: "movementSpeed",
  },
  {
    label: "To Counteroffensive",
    value: "counteroffensive",
  },
  {
    label: "To Heavy Handed",
    value: "heavyHanded",
  },
  {
    label: "To Cut to the Bone",
    value: "cutToTheBone",
  },
  {
    label: "To Slaying Strike",
    value: "slayingStrike",
  },
  {
    label: "To Brute Force",
    value: "bruteForce",
  },
  {
    label: "To No Mercy",
    value: "noMercy",
  },
  {
    label: "To Nature's Reach",
    value: "naturesReach",
  },
  {
    label: "To Wild Impulses",
    value: "wildImpulses",
  },
  {
    label: "To Envenom",
    value: "envenom",
  },
  {
    label: "To Crushing Earth",
    value: "crushingEarth",
  },
  {
    label: "To Resonance",
    value: "resonance",
  },
  {
    label: "To Defiance",
    value: "defiance",
  },
  {
    label: "To Wallop",
    value: "wallop",
  },
  {
    label: "To Call of the Wild",
    value: "callOfTheWild",
  },
  {
    label: "To Quickshift",
    value: "quickshift",
  },
  {
    label: "To Gloom",
    value: "gloom",
  },
  {
    label: "To Toxic Claws",
    value: "toxicClaws",
  },
  {
    label: "To Death's Embrace",
    value: "deathsEmbrace",
  },
  {
    label: "To Terror",
    value: "terror",
  },
  {
    label: "To Coalesced Blood",
    value: "coalescedBlood",
  },
  {
    label: "To Imperfectly Balanced",
    value: "imperfectlyBalanced",
  },
  {
    label: "To Compound Fracture",
    value: "compoundFracture",
  },
  {
    label: "To Tides of Blood",
    value: "tidesOfBlood",
  },
  {
    label: "To Evulsion",
    value: "evulsion",
  },
  {
    label: "To Hellbent Commander",
    value: "hellbentCommander",
  },
];

export const implicitOptions: Option[] = [
  {
    label: "Max Evade Charge",
    value: "maxEvadeCharge",
  },
  {
    label: "Attacks Reduce Evade's Cooldown by Seconds",
    value: "attacksReduceEvadeCooldown",
  },
  {
    label: "Evade Grants 125% Movement Speed for 2 Seconds",
    value: "evadeGrantsMovementSpeed",
  },
  {
    label: "Evade Grants Unhindered for 2 Seconds",
    value: "evadeGrantsUnhindered",
  },
  {
    label: "Resistance to All Elements",
    value: "resistance-to-all-elements",
  },
];

export const legendaryOptions: Option[] = [
  {
    label:
      "Whenever you deal direct damage while Berserking, inflict of the Base damage dealt as additional Bleeding damage over 5 seconds",
    value: "berserkingBleedDamage",
  },
  {
    label:
      "Ground Stomp creates an Earthquake damaging enemies for Physical damage over 4 seconds. While standing in Earthquakes, you deal increased damage",
    value: "groundStompEarthquake",
  },
  {
    label:
      "Stunning a Bleeding enemy deals of their total Bleeding amount to them as Physical damage",
    value: "stunBleedDamage",
  },
  {
    label:
      "Your Weapon Mastery Skills have an additional Charge. Lucky Hit: Damaging an enemy with a Weapon Mastery Skill has up to a chance to Stun them for 2 seconds",
    value: "weaponMasteryChargeStun",
  },
  {
    label: "Gain Damage Reduction for each Nearby Bleeding enemy up to maximum",
    value: "bleedingEnemyDamageReduction",
  },
  {
    label: "Gain Berserking for seconds after swapping weapons 8 times",
    value: "weaponSwapBerserking",
  },
  {
    label:
      "Your Shout Skills generate Primary Resource per second while active",
    value: "shoutResourceGeneration",
  },
  {
    label: "Iron Skin grants Unstoppable, and Damage Reduction",
    value: "ironSkinUnstoppable",
  },
  {
    label:
      "Reduces the Cooldown of Leap by seconds per enemy hit, up to a maximum of 9 seconds. Hitting a Boss with Leap provides the maximum reduction",
    value: "leapCooldownReduction",
  },
  {
    label:
      "Lucky Hit: Damaging an enemy with a Core Skill has up to a chance to extend the duration of Berserking by 2 seconds. Double this duration if it was a Critical Strike",
    value: "coreBerserkingExtension",
  },
  {
    label:
      "Killing an enemy with a Core Skill refunds of its base cost. Can only happen once per Skill cast",
    value: "coreSkillRefund",
  },
  {
    label:
      "Lucky Hit: Damaging enemies with Leap, Upheaval, or Whirlwind have up to a chance to summon an Ancient to perform the same Skill",
    value: "summonAncientSkill",
  },
  {
    label: "Each point of Fury generated while at Maximum Fury grants Fortify",
    value: "maxFuryFortify",
  },
  {
    label:
      "Enemies damaged by Kick or Charge will explode if they are killed within the next 2 seconds, dealing damage to surrounding enemies",
    value: "kickChargeExplosion",
  },
  {
    label:
      "Whirlwind leaves behind Dust Devils that deal damage to enemies in their path",
    value: "whirlwindDustDevils",
  },
  {
    label:
      "After spending 100 Fury, your next Weapon Mastery Skill within 5 seconds deals increased damage",
    value: "furyWeaponMasteryBonus",
  },
  {
    label:
      "Gain increased Fury Generation while all damage bonuses from the Walking Arsenal Key Passive are active",
    value: "walkingArsenalFuryBonus",
  },
  {
    label: "You gain Fury per second while Berserking",
    value: "berserkingFuryGeneration",
  },
  {
    label: "Gain Thorns while Berserking",
    value: "berserkingThorns",
  },
  {
    label:
      "Whirlwind's Critical Strike Chance is increased by for each second it is channeled, up to",
    value: "whirlwindCriticalChance",
  },
  {
    label:
      "Leap creates an Earthquake that deals Physical damage over 4 seconds. While standing in Earthquakes, you gain increased Damage Reduction",
    value: "leapEarthquake",
  },
  {
    label:
      "Casting Double Swing creates a Dust Devil that deals damage to enemies in its path",
    value: "doubleSwingDustDevil",
  },
  {
    label:
      "Each time a Core Skill deals direct damage to an enemy, your next Charge, Leap, or Kick deals increased damage, up to",
    value: "coreSkillMovementBonus",
  },
  {
    label:
      "Hammer of the Ancients quakes outward for increased damage. This bonus is doubled against Bosses",
    value: "hammerQuakeDamage",
  },
  {
    label: "Whirlwind periodically Pulls enemies to you",
    value: "whirlwindPull",
  },
  {
    label:
      "Up to a 35% chance to gain Fortify whenever you deal direct damage while Berserking",
    value: "berserkingFortifyChance",
  },
  {
    label:
      "Whenever you cast a Shout Skill, its Cooldown is reduced by seconds per Nearby enemy, up to a maximum of 6 seconds",
    value: "shoutCooldownReduction",
  },
  {
    label: "Damaging an enemy with Kick or Ground Stomp resets Leap's Cooldown",
    value: "kickGroundStompLeapReset",
  },
  {
    label:
      "Lucky Hit: Damaging Bleeding or Vulnerable enemies has up to a chance to Stun them for 2 seconds",
    value: "bleedVulnerableStun",
  },
  {
    label:
      "Lucky Hit: You have up to a chance to gain 20 Fury when Rend deals direct damage to at least one Bleeding enemy",
    value: "rendBleedingFury",
  },
  {
    label:
      "After swapping weapons 8 times, your next non-Basic Skill will Overpower and deal increased Overpower damage",
    value: "weaponSwapOverpower",
  },
  {
    label:
      "Gain increased Lucky Hit Chance while all damage bonuses from the Walking Arsenal Key Passive are active",
    value: "walkingArsenalLuckyHit",
  },
  {
    label:
      "Killing an enemy while Berserking has a 40% chance to grant increased Cooldown Reduction to your Brawling Skills for 2 seconds",
    value: "berserkingBrawlingCooldown",
  },
  {
    label:
      "While Berserking, you deal Fire damage every second to surrounding enemies",
    value: "berserkingFireDamage",
  },
  {
    label:
      "After generating 100 Fury your next direct damage creates 2 Dust Devils that deal damage to enemies along their path",
    value: "furyDustDevils",
  },
  {
    label:
      "Each point of Fury you generate while at Maximum Fury grants your next Core Skill within 5 seconds increased damage, up to",
    value: "maxFuryCoreDamage",
  },
  {
    label:
      "Charge calls forth 4 Ancients who also Charge, dealing of normal damage",
    value: "chargeAncients",
  },
  {
    label:
      "Your Rupture does not remove the Bleeding damage from enemies. This can occur once every seconds",
    value: "ruptureBleedRetention",
  },
  {
    label:
      "Every 20 seconds, your next Upheaval cast is guaranteed to Overpower and deals increased damage. Critical Strikes with Upheaval against Elites reduce this timer by 1 second",
    value: "upheavalOverpower",
  },
  {
    label:
      "Your Shout Skills create 3 Dust Devils that deal damage to enemies along their path. Your Dust Devils are bigger and deal 1% increased damage for each 1% their size is increased",
    value: "shoutDustDevils",
  },
];

export const uniqueOptions: Option[] = [
  {
    value: "spendPrimarySource",
    label:
      "After you spend 275 of your Primary Resource, your next Core Skill is guaranteed to Overpower. Your Critical Strikes that Overpower deal # increased damage",
  },
];

export const aspectOptions = [
  {
    value: "aspect_1",
    label: "Aspect 1",
  },
  {
    value: "aspect_2",
    label: "Aspect 2",
  },
];

export const orderTypeOptions: Option[] = [
  {
    value: "wts",
    label: "WTS (I Want to Sell)",
  },
  {
    value: "wtb",
    label: "WTS (I Want to Buy)",
  },
];

export const auctionOptions = [
  {
    value: "auction_1",
    label: "Auction 1",
  },
  {
    value: "auction_2",
    label: "Auction 2",
  },
];

export const consumableCategoryOptions: Option[] = [
  { label: "Elixir", value: "elixir" },
  { label: "Incense", value: "incense" },
  { label: "Boss Materials", value: "bossmaterials" },
  { label: "Summon Sets", value: "summonsets" },
  { label: "Gem", value: "gem" },
  { label: "Scroll", value: "scroll" },
  { label: "Rune", value: "rune" },
];

export const NotificationOptions: Option[] = [
  { label: "Notification", value: "Notification" },
];

export const affixStatFilter: Option[] = [
  { label: "Maximum Life", value: "maximumLife" },
  { label: "Critical Strike Chance", value: "criticalStrikeChance" },
  { label: "Life Per Second", value: "lifePerSecond" },
  { label: "Spirit Per Second", value: "spiritPerSecond" },
  { label: "Ranks of Tornado", value: "ranksOfTornado" },
  {
    label: "Attacks Reduce Evade's Cooldown by # Seconds ",
    value: "reduceAttacks",
  },
];

export const searchInputHome: Option[] = [
  {
    label: "Two-Handed Axe; Legendary; Ancestral; Any; Any; Maximum Life (GA)",
    value: "twoHandedAxeLife",
  },
  {
    label:
      "One-Handed Axe; Legendary; Ancestral; Any; Any; Vulnerable Damage (GA)",
    value: "oneHandedAxeDamage",
  },
  {
    label: "Chest Armor; Legendary; Ancestral; Any; Any; Maximum Life (GA)",
    value: "chestArmorMaximumLife",
  },
  {
    label: "Two-Handed Axe; Legendary; Ancestral; Any; Any; Dexterity (GA)",
    value: "twoHandedAxeDexterity",
  },
  {
    label: "Chest Armor; Legendary; Ancestral; Any; Any; Maximum Life (GA)",
    value: "chestArmorLife",
  },
  {
    label:
      "Staff; Legendary; Ancestral; Any; Any; Dexterity (GA); Maximum Life (GA); Aspect of Retribution...",
    value: "staffRetribution",
  },
  {
    label: "One-Handed Axe; Legendary; Ancestral; Any; Any; Dexterity (GA)",
    value: "oneHandedAxeDexterity",
  },
  {
    label:
      "Staff; Legendary; Ancestral; Any; Any; Max Price 100,000,000; Dexterity (GA); Maximum Life (GA); Aspect of Retribution...",
    value: "staffAspectOfRetribution",
  },
];
