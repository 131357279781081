import { signIn } from "next-auth/react";

export function signInWithTimezone() {
  if (typeof window === "undefined") {
    throw new Error("This function must be called in a browser context");
  }

  const currentUrl = new URL(
    process.env.AUTH_URL ? process.env.AUTH_URL : window.location.href,
  );

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  currentUrl.searchParams.append("timezone", timezone);

  return signIn("battlenet", {
    redirectTo: process.env.NEXT_PUBLIC_NEXTAUTH_URL,
    redirect: true,
  });
}
