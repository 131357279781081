import(/* webpackMode: "eager" */ "/app/apps/marketplace/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["MSWProvider"] */ "/app/apps/marketplace/src/msw/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/marketplace/src/providers/react-query.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/app/node_modules/.pnpm/next-auth@5.0.0-beta.25_next@15.0.1_react-dom@19.0.0-rc.1_react@19.0.0-rc.1__react@19.0.0-rc.1__react@19.0.0-rc.1/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc.1_react@19.0.0-rc.1__react@19.0.0-rc.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/PTSerif.ttf\",\"variable\":\"--font-sans\",\"preload\":true}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc.1_react@19.0.0-rc.1__react@19.0.0-rc.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/OldFenris.woff2\",\"weight\":\"700\",\"variable\":\"--font-diablo\",\"preload\":true}],\"variableName\":\"diabloFont\"}");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/dist/index.css");
