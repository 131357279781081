"use client";

import { twMerge } from "tailwind-merge";
import { CloseButton } from "@/components/close-button";

export function Middle({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element {
  return (
    <div className={twMerge("w-[820px] overflow-hidden", className)}>
      {children}
    </div>
  );
}

Middle.Header = Header;
Middle.Content = Content;
Middle.Title = Title;
Middle.CloseBtn = CloseBtn;

function Header({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="relative mb-4 grid h-10 items-center">{children}</div>;
}

function Content({
  children,
  className,
}: React.HTMLAttributes<HTMLDivElement>): JSX.Element {
  return <div className={twMerge("px-2", className)}>{children}</div>;
}

function Title({ children }: React.PropsWithChildren) {
  return (
    <h2 className="pr-8 text-center font-diablo font-bold uppercase">
      {children}
    </h2>
  );
}

function CloseBtn(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return <CloseButton className="absolute right-2 top-3" {...props} />;
}
